<!-- eslint-disable -->
<template>
    <div class="contact-data">
        <Progress state="3" />
        <div class="mt-10 sm:mt-0">
            <form @submit.prevent="submit" class="space-y-12">                
                <div class="md:grid md:grid-cols-3 md:gap-6">
                    
                    <!-- SIDE -->
                    <div class="md:col-span-1">
                        <h2
                            class="text-lg font-semibold leading-6 text-emerald-700"
                        >
                            Kostenübernahme mit Ihrer Pflegekasse
                        </h2>
                        <p class="mt-1 text-sm text-gray-600">
                          Bei gesetzlich Versicherten übernehmen wir die Abrechnung. Privatversicherte reichen die beiliegende Rechnung zur Erstattung bei ihrer Pflegekasse ein.
                        </p>
                    </div>
                    <!-- CARD -->
                    <div class="mt-5 md:mt-0 md:col-span-2">
                        <div class="shadow bg-white rounded-lg">
                            <div class="px-4 py-5 sm:p-6">
                                <h3 class="text-base font-semibold text-gray-900" >
                                    Angaben zur Krankenkasse
                                </h3>
                                <div class="grid grid-cols-6 gap-6">
                                    <div class="col-span-6">
                                        <div class="flex mt-4 space-x-4">
                                            <div class="flex items-center">
                                                <label class="text-sm w-max">
                                                    <input 
                                                      class="focus:ring-cyan-500 h-4 w-4 text-cyan-600 border-gray-300 rounded" 
                                                      type="checkbox" 
                                                      name="vtype" 
                                                      id="vtype"
                                                      value="private"
                                                      v-model="form.vtype"
                                                    >
                                                    <span class="ml-2">Privat versichert</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div 
                                      class="col-span-6 sm:col-span-2"
                                      :class="{ 'hidden': form.vtype }"
                                      >
                                        <label
                                            class="block text-sm text-gray-700"
                                            :class="{ 'text-red-400': v$.form.insurantnumber.$errors.length > 0, 'text-green-500': !v$.form.insurantnumber.$silentErrors.length, }"
                                            for="insurantnumber"
                                            >Versichertennummer *
                                        </label>
                                        <input
                                            class="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            type="text"
                                            name="insurantnumber"
                                            id="insurantnumber"
                                            v-model="form.insurantnumber"
                                        />
                                    </div>
                                    <div 
                                      class="col-span-6 sm:col-span-4"
                                      :class="{ 'hidden': form.vtype }"
                                      >
                                        <label
                                            class="block text-sm text-gray-700"
                                            :class="{ 'text-red-400': v$.form.insurance.$errors.length > 0, 'text-green-500': !v$.form.insurance.$silentErrors.length, }"
                                            for="insurance"
                                            >Krankenkasse *
                                        </label>
                                        <vue-select
                                            class="mec-chooser relative z-10"
                                            :options="insurances"
                                            :reduce="insurance => insurance.ID"
                                            label="Name"
                                            v-model="form.insurance"
                                            name="insurance"
                                            :clearable="false"
                                            >
                                            <template #no-options="{ search, searching, loading }">
                                                Krankenkassen nicht gefunden.
                                            </template>
                                            <template #search="{ attributes, events }">
                                                <input
                                                class="vs__search"
                                                v-bind="attributes"
                                                v-on="events"
                                                />
                                            </template>
                                        </vue-select>
                                    </div>
                                    <div class="col-span-6">
                                      <label 
                                        class="block text-sm text-gray-700"
                                        :class="{ 'text-red-400': v$.form.birthday.$errors.length > 0, 'text-green-500': !v$.form.birthday.$silentErrors.length, }"
                                        >Geburtsdatum *</label
                                      >
                                      <datepicker
                                        inputFormat="dd.MM.yyyy"
                                        placeholder="TT.MM.JJJJ"
                                        class="
                                          mt-1
                                          focus:ring-cyan-500 focus:border-cyan-500
                                          block
                                          w-full
                                          shadow-sm
                                          sm:text-sm
                                          border-gray-300
                                          rounded-md
                                        "
                                        v-model="form.birthday"
                                        :locale="de"
                                        startingView="year"
                                      />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- SIDE -->
                    <div class="md:col-span-1 mt-6">
                        <h2
                            class="text-lg font-semibold leading-6 text-emerald-700"
                        >
                            Unterschreiben und Box bestellen
                        </h2>
                        <p class="mt-1 text-sm text-gray-600">
                            Nach Ihrer Unterschrift beantragen wir für Sie die Genehmigung bei Ihrer Pflegekasse und senden Ihnen monatlich die gewünschten Pflegehilfsmittel zu.
                        </p>
                    </div>
                    <!-- SIGNING -->
                    <div class="col-span-2 col-start-2 mt-6">
                        <div class="p-6 bg-white rounded-lg shadow">
                          <div class="col-span-2">
                              <h3 class="text-base font-semibold text-gray-900" :class="{ 'text-red-400': v$.form.signature_filled.$errors.length > 0 }">Unterschrift des Versicherten oder Bevollmächtigten</h3>
                              <p class="mt-1 text-sm text-gray-500" :class="{ 'text-red-400': v$.form.signature_filled.$errors.length > 0 }">
                                  Nutzen Sie Ihre Maus, Trackpad oder Finger, um Ihre Unterschrift in das gelb umrandete Feld zu zeichnen.
                                  </p>
                          </div>
                          <div class="relative z-0 col-span-2 h-64 w-full mt-6">
                              <canvas ref="signing_canvas" class="w-full h-full bg-white border-4 border-yellow-400 rounded-lg" :class="{ 'border-red-400': v$.form.signature_filled.$errors.length > 0 }" ></canvas>
                              <div class="absolute bottom-6 right-6">
                                  <svg xmlns="http://www.w3.org/2000/svg" class="w-12 h-12 text-gray-200" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                      <path d="M8 13v-8.5a1.5 1.5 0 0 1 3 0v7.5" />
                                      <path d="M11 11.5v-2a1.5 1.5 0 1 1 3 0v2.5" />
                                      <path d="M14 10.5a1.5 1.5 0 0 1 3 0v1.5" />
                                      <path d="M17 11.5a1.5 1.5 0 0 1 3 0v4.5a6 6 0 0 1 -6 6h-2h.208a6 6 0 0 1 -5.012 -2.7a69.74 69.74 0 0 1 -.196 -.3c-.312 -.479 -1.407 -2.388 -3.286 -5.728a1.5 1.5 0 0 1 .536 -2.022a1.867 1.867 0 0 1 2.28 .28l1.47 1.47" />
                                      </svg>
                              </div>
                          </div>
                          <div class="flex justify-between items-center col-span-2 mt-6">
                              <button class="flex content-center px-2 py-1 border-2 border-gray-400 bg-gray-50 rounded-md" @click="clearSigning()" >
                                  <svg xmlns="http://www.w3.org/2000/svg" class="mt-1 h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                  </svg>
                                  <span class="ml-2 text-gray-600">neuer Versuch</span>
                              </button>
                          </div>

                          <div class="flex justify-between items-center col-span-2 mt-6">
                              <div class="relative inline-block flex-shrink-0 w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                  <input class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer" 
                                  type="checkbox" 
                                  name="signterms"
                                  id="signterms"
                                  v-model="form.signing_terms_accepted"
                                  />
                                  <label for="toggle" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                              </div>
                              <label for="signterms" class="text-sm text-gray-500" :class="{ 'text-red-500': v$.form.signing_terms_accepted.$errors.length > 0 }" > Ich stimme den <a href="#" target="_blank" class="border-b border-gray-500 border-solid">Allgemeinen Geschäftsbedingungen</a> der Mecommerce GmbH zum Produkt Box zu habe die Hinweise zum <a href="mailto:kontakt@mecurio.de" target="_blank" class="border-b border-gray-500 border-solid">Widerrufsrecht</a> zur Kenntnis genommen.</label>
                          </div>
                          <div class="flex">
                              <div class="relative inline-block flex-shrink-0 w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                  <input class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer" 
                                  type="checkbox" 
                                  name="signauth"
                                  id="signauth"
                                  v-model="form.signing_authorization"
                                  />
                                  <label for="toggle" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                              </div>
                              <label for="signauth" class="text-sm text-gray-500" :class="{ 'text-red-500': v$.form.signing_authorization.$errors.length > 0 }">Sofern ich die oben genannten Einwilligungen und Erklärungen für einen Dritten, z.B. eine pflegebedürftige Person abgebe, versichere ich, dass mich die Dritte Person zur Angabe der Einwilligungeserklärung bevollmächtigt hat und kann der Mecommerce GmbH diese Vollmacht jederzeit vorlegen.</label>
                          </div>

                        </div>
                        <div class="flex justify-between mt-6 px-4 py-3 sm:px-6">
                            <button class="inline-flex justify-center py-2 px-4 border-2 border-emerald-600 shadow-sm text-sm font-semibold rounded-md text-emerald-600  hover:bg-emerald-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500" onclick="history.go(-1); return false;" >Zurück</button>
                            <button class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"  type="submit" :disabled="submitStatus === 'PENDING'" >Antrag absenden</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<!-- eslint-enable -->


<script>
import { mapGetters, mapState } from "vuex";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import SignaturePad from "signature_pad";
import Progress from "./Progress.vue";
import vSelect from "vue-select";
import datepicker from "vue3-datepicker";
import { de } from "date-fns/locale";

export default {
  components: {
    "vue-select": vSelect,
    Progress,
    datepicker,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data: () => ({
    de,
    signaturePad: null,
    submitStatus: "",
    form: {
        signature_filled: false,
        signing_terms_accepted: false,
        signing_authorization: false,
        insurantnumber: "",
        insurance: null,
        vtype: "",
        birthday: null,
    },
  }),
  validations() {
    return {
      form: {
        signature_filled: { checked: (val) => val },
        signing_authorization: { checked: (val) => val },
        signing_terms_accepted: { checked: (val) => val },
        insurantnumber: { required : this.form.vtype ? false : required},
        insurance: { required : this.form.vtype ? false : required},
        vtype: {},
        birthday: { required },
      },
    };
  },
  methods: {
    clearSigning() {
      this.form.signature_filled = false;
      this.signaturePad.clear();
    },
    submit() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.submitStatus = "PENDING";
        const form = {
          ...this.form,
          birthday: this.form.birthday.getTime(),
        };
        form.provider_change = form.provider_change === true ? 1 : 0;

        this.$store.dispatch("user/update", {
          data: form,
          cb: (a) => {
            if (a === true) {
              this.$store.dispatch("user/signing", {
                data: {
                    cost_coverage_signed: true,
                    provider_change_signed: true,
                    signing_terms_accepted: this.form.signing_terms_accepted,
                    signing_authorization: this.form.signing_authorization,
                    signature_filled: this.form.signature_filled,
                    ...{ signature: this.signaturePad.toDataURL("image/png") }
                },
                cb: (a) => {
                  if (a === true) {
                    this.submitStatus = "OK";
                    this.$store.dispatch("cart/clearCart", {});
                    this.$router.push("/abschluss");
                  } else {
                    this.submitStatus = "ERROR";
                    console.log("NEED ERROR HANDLING 2");
                  }
                }
              });
            } else {
              this.submitStatus = "ERROR";
              console.log("NEED ERROR HANDLING 1");
            }
          }
        });
      }
    },
    resizeCanvas() {
      var canvas = this.$refs.signing_canvas;
      var ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      canvas.getContext("2d").scale(ratio, ratio);
    },
  },
  computed: {
    ...mapState({
      insurances: (state) => state.insurances.all,
    }),
    ...mapGetters("cart", {
      minPrice: "cartMinPrice",
    }),
  },
  mounted() {
    if (!this.minPrice) {
      this.$router.push({ name: "ProductSelection" });
      this.$store.dispatch("user/clearToken");
      return;
    }
    this.form = { ...this.form, ...this.$store.getters["user/data"] };
    if (this.form.birthday !== null) {
      this.form.birthday = new Date(this.form.birthday);
    }
    const app = this;
    var canvas = this.$refs.signing_canvas;
    this.signaturePad = new SignaturePad(canvas);
    this.resizeCanvas();
    this.signaturePad.onEnd = function () {
      app.form.signature_filled = !app.signaturePad.isEmpty();
    };
    this.$store.dispatch("insurances/getAllInsurances");
  },
  unmounted() {
    //window.removeEventListener("resize", this.resizeCanvas);
  },
};
</script>

<style lang="less">
.clear_signing {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.signing_cross {
  position: absolute;
  bottom: -24px;
  left: -9px;
  pointer-events: none;

  svg {
    width: 75px;
  }
}
.signing_canvas {
  width: 100%;
  height: 300px;
  border-bottom: 1px solid currentColor;

  &.error {
    border-color: #f0506e;

    + .signing_cross {
      color: #f0506e !important;
    }
  }
}
.lightbox {
  a {
    position: relative;
    display: inline-block;
  }
  .uk-icon {
    position: absolute;
    right: 0;
    bottom: 0;
    svg {
      width: 41px;

      circle {
        fill: #fff;
      }
    }
  }
}
</style>

<style lang="postcss">
.mec-chooser .vs__dropdown-toggle {
  @apply relative flex justify-between py-2 px-3 shadow-sm sm:text-sm border-gray-300 rounded-md border focus-within:ring-cyan-500 focus-within:ring-2;
}

.mec-chooser .vs__dropdown-menu {
  @apply absolute py-2 px-3 bg-white max-h-64 max-w-max overflow-scroll shadow-sm sm:text-sm border-gray-300 rounded-md border focus-within:ring-cyan-500 focus-within:ring-2;
}

.mec-chooser {
  @apply mt-1;
}
.mec-chooser .vs__search,
.vs__search:focus {
  border: none;
  padding: 0;
  font-size: 0.875rem;
  line-height: 1.25rem;
  @apply focus:ring-white;
}

.mec-chooser .vs__clear,
.mec-chooser .vs__open-indicator {
  fill: gray-300;
}

.mec-chooser .vs__selected-options {
  flex-wrap: nowrap;
}

span.vs__selected {
  position: absolute;
  @apply w-11/12 text-sm;
}

.vs__dropdown-option--highlight {
  @apply bg-gray-100;
}

.vs__actions {
  @apply flex justify-center items-center;
}
</style>
